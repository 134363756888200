//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { now } from 'kolibri.utils.serverClock';
import CoreFullscreen from 'kolibri.coreVue.components.CoreFullscreen';
import Hashi from 'hashi';
import { nameSpace } from 'hashi/src/hashiBase';
import plugin_data from 'plugin_data';

// Regex vendored from https://github.com/faisalman/ua-parser-js/blob/master/src/ua-parser.js
const iOSTest = /ip[honead]{2,4}(?:.*os\s([\w]+)\slike\smac|;\sopera)/i;
const IE11Test = /(trident).+rv[:\s]([\w.]+).+like\sgecko/i;

const defaultContentHeight = '500px';
const frameTopbarHeight = '37px';
const pxStringAdd = (x, y) => parseInt(x, 10) + parseInt(y, 10) + 'px';

export default {
  name: 'Html5AppRendererIndex',
  components: {
    CoreFullscreen,
  },
  data() {
    return {
      isInFullscreen: false,
    };
  },
  computed: {
    name() {
      return nameSpace;
    },
    rooturl() {
      const iOS = iOSTest.test(navigator.userAgent);
      const iOSorIE11 = iOS || IE11Test.test(navigator.userAgent);
      // Skip hashi on requests for these browsers
      return this.defaultFile.storage_url + (iOSorIE11 ? '?SKIP_HASHI=true' : '');
    },
    iframeHeight() {
      return (this.options && this.options.height) || defaultContentHeight;
    },
    iframeWidth() {
      return (this.options && this.options.width) || 'auto';
    },
    contentRendererHeight() {
      return pxStringAdd(this.iframeHeight, frameTopbarHeight);
    },
    sandbox() {
      return plugin_data.html5_sandbox_tokens;
    },
    fullscreenText() {
      return this.isInFullscreen ? this.$tr('exitFullscreen') : this.$tr('enterFullscreen');
    },
    userData() {
      return {
        userId: this.userId,
        userFullName: this.userFullName,
        progress: this.progress,
        complete: this.progress >= 1,
        language: this.lang.id,
        timeSpent: this.timeSpent,
      };
    },
    containerStyle() {
      if (this.isInFullscreen) {
        return {
          position: 'absolute',
          top: frameTopbarHeight,
          bottom: 0,
        };
      }
      return { height: this.iframeHeight };
    },
  },
  watch: {
    userData(newValue) {
      if (newValue && this.hashi) {
        this.hashi.updateData({ userData: newValue });
      }
    },
  },
  mounted() {
    this.hashi = new Hashi({ iframe: this.$refs.iframe, now });
    this.hashi.onStateUpdate(data => {
      this.$emit('updateContentState', data);
    });
    this.hashi.initialize(
      (this.extraFields && this.extraFields.contentState) || {},
      this.userData
    );
    this.$emit('startTracking');
    this.startTime = now();
    this.pollProgress();
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.$emit('stopTracking');
  },
  methods: {
    recordProgress() {
      const totalTime = now() - this.startTime;
      const hashiProgress = this.hashi ? this.hashi.getProgress() : null;
      this.$emit(
        'updateProgress',
        hashiProgress === null ? Math.max(0, totalTime / 300000) : hashiProgress
      );
      this.pollProgress();
    },
    pollProgress() {
      this.timeout = setTimeout(() => {
        this.recordProgress();
      }, 15000);
    },
  },
  $trs: {
    exitFullscreen: 'Exit Fullscreen',
    enterFullscreen: 'View Fullscreen',
  },
};

